import React, { useState, useEffect, useRef } from "react";
import Footer from "../../../shared/Footer/Footer";
import NavBar from "../../../shared/NavBar/NavBar";
import NewsCardA from "../../../shared/NewsCardA/NewsCardA";
import NewsCardB from "../../../shared/NewsCardB/NewsCardB";
import NewsCardC from "../../../shared/NewsCardC/NewsCardC";
import { INews } from "../../../dtos/news";
import axios from "axios";
import { apiUrl } from "../../../util/API";

interface newsProps {
  setScroll?: any;
}
export default function News(props: newsProps) {
  const [news, setNews] = useState<INews[]>([]);

  const topScroll = useRef<HTMLDivElement>(null);

  useEffect(() => {
    topScroll.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/news-stories`, {
        params: {
          populate: "*",
          sort: "createdAt:desc",
        },
      })
      .then((res) => {
        setNews(res.data.data);
      })
      .catch(() => {
        setNews([]);
      });
  }, []);

  return (
    <>
      <div
        className="container-fluid bg-bg"
        style={{ padding: "0", margin: "0" }}
        ref={topScroll}
      >
        <NavBar active="news" fullNav={false} setScroll={props.setScroll} />
        <section className="py-5 mb-4" style={{ minHeight: "100vh" }}>
          <div className="container">
            <div className="row text-white">
              <div className="col-md-12">
                <p className="h2 mb-4" data-aos="fade-up">
                  News
                </p>
                <hr className="bg-dark-bg" />
                <div className="row pt-1 ">
                  {news[0] && (
                    <div
                      className="col-md-6 my-lg-3 my-md-3"
                      data-aos="fade-up"
                    >
                      <NewsCardB
                        news={news[0].attributes.news}
                        date={news[0].attributes.date}
                        image={
                          apiUrl + news[0].attributes.image.data.attributes.url
                        }
                      />
                    </div>
                  )}
                  <div className="col-md-6" data-aos="fade-up">
                    {news.slice(1, 3).map((news, index) => {
                      return (
                        <NewsCardC
                          news={news.attributes.news}
                          date={news.attributes.date}
                          tag={news.attributes.tag}
                          key={index}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-12" data-aos="fade-up">
                    {news.slice(3, 9).map((news, index) => {
                      return (
                        <NewsCardA
                          news={news.attributes.news}
                          date={news.attributes.date}
                          tag={news.attributes.tag}
                          key={index}
                        />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}
