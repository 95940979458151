import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import "./ProjectCard.scss";
import { Link } from "react-router-dom";

interface CardData {
  title: String;
  category: String;
  status: String;
  id: string;
  image: string;
  peopleInvolved: string[];
  learnMoreAlternative?: string;
}

const ProjectCard = (props: CardData) => {
  return (
    <Card className="bg-dark-bg  my-3 h-100">
      <Card.Img
        variant="top"
        src={props.image}
        height={"200px"}
        style={{ objectFit: "cover" }}
      />
      <Card.Body className="col justify-content-between px-4 py-4">
        <Card.Title className="h5">{props.title}</Card.Title>
        <div className="d-flex my-3">
          <Badge pill bg="info px-2" style={{ fontWeight: "400" }}>
            {props.category}
          </Badge>{" "}
          <Badge
            pill
            bg="warning px-2 text-bg mx-2"
            style={{ fontWeight: "400" }}
          >
            {props.status}
          </Badge>{" "}
        </div>
        <div className="d-flex my-3 flex-wrap">
          {props.peopleInvolved?.map((people, index) => {
            return (
              <Badge
                pill
                bg="bg px-2 text-bg m-1 text-white p-2 px-3"
                key={index}
                style={{ fontWeight: "400" }}
                className="multiline-badge"
              >
                {people}
              </Badge>
            );
          })}
        </div>
      </Card.Body>
      <div className="card-footer px-4 pb-3">
        <Link
          to={
            props.learnMoreAlternative
              ? props.learnMoreAlternative
              : `/projects/${props.title.replaceAll(" ", "-")}?id=${props.id}`
          }
          className="text-underline-none"
          target={props.learnMoreAlternative ? "_blank" : "_self"}
        >
          <p
            className="text-primary_2  text-underline-none pb-1 mb-0 link-text"
            style={{ textDecoration: "none" }}
          >
            View Project Info
          </p>
        </Link>
      </div>
    </Card>
  );
};

export default ProjectCard;
