import React, { useRef, useEffect, useState } from "react";
import Footer from "../../../shared/Footer/Footer";
import NavBar from "../../../shared/NavBar/NavBar";
import PublicationCard from "../../../shared/PublicationCard/PublicationCard";
import axios from "axios";
import { apiUrl } from "../../../util/API";
import { IPublication } from "../../../dtos/publications";
interface PubProps {
  setScroll?: any;
}
export default function Publications(props: PubProps) {
  const topScroll = useRef<HTMLDivElement>(null);
  const [publications, setPublications] = useState<IPublication[]>([]);

  useEffect(() => {
    topScroll.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/publications`, {
        params: {
          populate: "*",
          sort: "date:desc",
        },
      })
      .then((res) => {
        console.log(res.data.data);
        setPublications(res.data.data);
      })
      .catch(() => {
        setPublications([]);
      });
  }, []);

  return (
    <>
      <div
        className="container-fluid bg-bg"
        style={{ padding: "0", margin: "0" }}
        ref={topScroll}
      >
        <NavBar
          active="publications"
          fullNav={false}
          setScroll={props.setScroll}
        />
        <section className="py-5 mb-4" style={{ minHeight: "100vh" }}>
          <div className="container">
            <div className="row text-white">
              <div className="col-md-12">
                <p className="h2 mb-4" data-aos="fade-up">
                  Publications
                </p>
                <hr className="bg-dark-bg" />
                <div className="row text-white pt-1 ">
                  {publications.map((publication, index) => {
                    return (
                      <div
                        className="col-md-4 py-3"
                        data-aos="fade-right"
                        data-aos-delay={index * 100}
                        key={index}
                      >
                        <PublicationCard
                          id={publication.id}
                          title={publication.attributes.title}
                          image={
                            apiUrl +
                            publication.attributes.image?.data.attributes.url
                          }
                          category={publication.attributes.category}
                          conference={publication.attributes.conference}
                          authors={publication.attributes.authors}
                          date={publication.attributes.date}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}
