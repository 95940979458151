import NavBar from "../../../shared/NavBar/NavBar";

import { Link, useSearchParams } from "react-router-dom";

import "./ProjectInfo.scss";
import Badge from "react-bootstrap/Badge";
import cal_icon from "../../../assets/images/cal_icon.svg";

import Footer from "../../../shared/Footer/Footer";
import { useRef, useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../../util/API";
import { IProject } from "../../../dtos/project";
import moment from "moment";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

interface projectProps {
  setScroll?: any;
}
const PojectInfo = (props: projectProps) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const topScroll = useRef<HTMLDivElement>(null);
  const [project, setProject] = useState<IProject>();

  useEffect(() => {
    topScroll.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/projects/${id}`, {
        params: {
          populate: "*",
        },
      })
      .then((res) => {
        setProject(res.data.data);
      })
      .catch(() => {
        setProject(undefined);
      });
  }, [id]);

  return (
    <div
      className="container-fluid bg-bg"
      style={{ padding: "0", margin: "0" }}
      ref={topScroll}
    >
      <div style={{ minHeight: "100vh" }}>
        <NavBar fullNav={false} active="projects" setScroll={props.setScroll} />
        <div className="container my-5 rounded fade-in">
          <div className="row  bg-dark-bg rounded mx-1 mx-md-0">
            <div className="col-md-12 ">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb p-0 m-0 my-2 mx-1">
                  <li className="breadcrumb-item">
                    <Link to="/projects">Projects</Link>
                  </li>
                  <li className="breadcrumb-item active text-gray">
                    {project?.attributes.title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="container mt-2 rounded fade-in">
          <div className="row">
            <div className="col-md-12">
              <img
                src={apiUrl + project?.attributes.image.data.attributes.url}
                alt="image1"
                className="img-fluid cover-image rounded"
              />
            </div>

            <div className="row mt-3">
              <div className="col-md-12">
                <p className="h4 text-white">{project?.attributes.title}</p>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap">
              <Badge pill bg="info px-2 py-2 " style={{ fontWeight: "100" }}>
                {project?.attributes.category}
              </Badge>{" "}
              <Badge
                pill
                bg="warning px-2 py-2 text-bg mx-3"
                style={{ fontWeight: "100" }}
              >
                {project?.attributes.status}
              </Badge>{" "}
              {project?.attributes && (
                <div className="d-flex align-items-center text-gray justify-content-center">
                  <img src={cal_icon} alt="duration " width={"30px"} />
                  <p
                    className="mt-3 mx-2"
                    style={{ fontSize: "0.9em", fontWeight: "100" }}
                  >
                    {`${moment(project.attributes.startDate).format(
                      "MMMM YYYY"
                    )} - ${
                      project.attributes.endDate
                        ? moment(project.attributes.endDate).format("MMMM YYYY")
                        : "Present"
                    }`}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="container my-3 fade-in">
          <div className="row ">
            <div className="col-md-6">
              <div className="row">
                <p className="text-white h5 mx-2 mt-4 mt-md-0">
                  People Involved
                </p>
                <div className="col d-flex flex-wrap">
                  {project?.attributes.peopleInvolved.map((people, index) => {
                    return (
                      <Badge
                        pill
                        bg="dark-bg"
                        className="px-2 text-bg my-2 text-white p-2 px-3 mx-2"
                        style={{ fontSize: "0.9em", fontWeight: "100" }}
                        key={index}
                      >
                        {people}
                      </Badge>
                    );
                  })}
                </div>
              </div>
            </div>
            {project?.attributes.collaboratingOrganizations.data &&
              project?.attributes.collaboratingOrganizations.data.length >
                0 && (
                <div className="col-md-6 fade-in">
                  <div className="row">
                    <p className="text-white h5 mx-2 mt-4 mt-md-0">
                      Collaborating Organizations
                    </p>
                    <div className="col d-flex flex-wrap">
                      {project?.attributes.collaboratingOrganizations.data?.map(
                        (
                          collaboratingOrganization,
                          collaboratingOrganizationsIndex
                        ) => {
                          return (
                            <img
                              src={
                                apiUrl +
                                collaboratingOrganization.attributes.url
                              }
                              className="my-2 mx-1 col-logo"
                              alt="image2"
                              key={collaboratingOrganizationsIndex}
                            />
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className="container my-3 fade-in">
          <div className="row ">
            <p className="text-white h5 mx-2 mt-3">Project Overview</p>
            <div
              className="col-md-12 mt-2 text-white mx-2"
              style={{ textAlign: "justify" }}
            >
              {project?.attributes.projectOverview && (
                <BlocksRenderer content={project.attributes.projectOverview} />
              )}
            </div>
          </div>
        </div>

        <div className="container my-3">
          <div className="row ">
            <p className="text-white h5 mx-2  ">Outcomes</p>
            {project?.attributes.outcomes.map((outcome, index) => {
              return (
                <div className="col-md-12">
                  <ul className="text-white">
                    {outcome.title && <li>{outcome.title}</li>}
                    <ul>
                      {outcome.list.map((listItem, listItemIndex) => {
                        return listItem.link ? (
                          <a
                            href={listItem.link}
                            rel="noreferrer"
                            target={"_blank"}
                            key={listItemIndex}
                          >
                            <li className="text-gray">{listItem.text}</li>
                          </a>
                        ) : (
                          <li key={listItemIndex}>{listItem.text}</li>
                        );
                      })}
                    </ul>
                  </ul>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PojectInfo;
