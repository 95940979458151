import React, { useState, useRef, useEffect } from "react";
import Footer from "../../../shared/Footer/Footer";
import NavBar from "../../../shared/NavBar/NavBar";
import ProjectCard from "../../../shared/ProjectCard/ProjectCard";
import { Button, Modal } from "react-bootstrap";
import axios from "axios";
import { apiUrl } from "../../../util/API";
import { IProject } from "../../../dtos/project";
import { useLocation } from "react-router-dom";

interface ProjectProps {
  setScroll?: any;
  title?: string;
  area?: string;
  status?: string;
  image?: string;
  path?: string;
  supervisors?: { supervisorName: string }[];
}

const Projects = (props: ProjectProps) => {
  const location = useLocation();
  const filterParameters = new URLSearchParams(location.search);
  const supervisorFilter = filterParameters.get("member");
  const topScroll = useRef<HTMLDivElement>(null);
  const [projects, setProjects] = useState<IProject[]>([]);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const handleMessageModalClose = () => setShowMessageModal(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    topScroll.current?.scrollIntoView();
    axios
      .get(`${apiUrl}/api/projects`, {
        params: {
          populate: "*",
          sort: "showOrder:asc",
          filters: {
            peopleInvolved: {
              $containsi: supervisorFilter,
            },
          },
        },
      })
      .then((res) => {
        setProjects(res.data.data);
        if (res.data.data.length === 0) {
          setMessage("Member Not Found");
          setShowMessageModal(true);
        }
      })
      .catch(() => {
        setProjects([]);
      });
  }, [supervisorFilter]);

  return (
    <>
      <div
        className="container-fluid bg-bg "
        style={{ padding: "0", margin: "0" }}
        ref={topScroll}
      >
        <NavBar active="projects" fullNav={false} setScroll={props.setScroll} />
        <section className="py-5 mb-4 " style={{ minHeight: "100vh" }}>
          <div className="container">
            <div className="row text-white">
              <div className="col-md-12">
                <p className="h2 mb-4" data-aos="fade-up">
                  Collaborative Projects
                </p>
                <hr className="bg-dark-bg" />
                <div className="row text-white pt-1 fade-in">
                  {projects.map((project, index) => {
                    return (
                      <div
                        className="col-md-4 py-2"
                        key={project.id}
                        data-aos="fade-right"
                        data-aos-delay={index * 100}
                      >
                        <ProjectCard
                          title={project.attributes.title}
                          category={project.attributes.category}
                          status={project.attributes.status}
                          image={
                            apiUrl +
                            project.attributes.image.data.attributes.url
                          }
                          peopleInvolved={project.attributes.peopleInvolved}
                          id={project.id}
                          learnMoreAlternative={
                            project.attributes.learnMoreAlternative
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Modal
          show={showMessageModal}
          onHide={handleMessageModalClose}
          animation={true}
          centered
        >
          <Modal.Body className="text-center p-3">
            <p className="lead mt-2">{message}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="text-white"
              onClick={handleMessageModalClose}
            >
              Okay
            </Button>
          </Modal.Footer>
        </Modal>

        <Footer />
      </div>
    </>
  );
};

export default Projects;
