interface CardData {
  date: string;
  news: string;
  tag?: string;
}

const NewsCardA = (props: CardData) => {
  return (
    <>
      <div className="card bg-dark-bg py-3 my-3" data-aos="fade-up">
        <div className="container row align-items-center">
          <div className="col-md-3 align-items-center justify-content-start px-md-5 px-3">
            <span
              className="badge badge-pill bg-primary badge-primary lead px-3 py-2"
              style={{ fontWeight: "100" }}
            >
              {props.date}
            </span>
          </div>

          <div className="col-md-9 px-3">
            <p className=" mt-3" style={{ textAlign: "justify" }}>
              {props.news}
              <span
                className="badge badge-pill bg-warning text-white badge-primary lead mx-2"
                style={{
                  display: props.tag === "" || props.tag === null ? "none" : "",
                  fontWeight: "100",
                  fontSize: "0.9em",
                }}
              >
                {props.tag}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsCardA;
