import "./shared/bootstrap/bootstrap.scss";
import "./shared/styles/styles.scss";
import Home from "./components/pages/home/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Projects from "./components/pages/projects/Projects";
import ProjectInfo from "./components/pages/projectInfo/ProjectInfo";
import Publications from "./components/pages/publications/Publications";
import News from "./components/pages/news/News";
import { useState } from "react";
import PublicationInfo from "./components/pages/publicationInfo/publicationInfo";

function App() {
  const [scrollInto, setScrollInto] = useState();
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home scrollTo={scrollInto} />} />
        <Route
          path="projects"
          element={<Projects setScroll={setScrollInto} />}
        />
        <Route
          path="publications"
          element={<Publications setScroll={setScrollInto} />}
        />
        <Route path="news" element={<News setScroll={setScrollInto} />} />
        <Route
          path="/projects/:name"
          element={<ProjectInfo setScroll={setScrollInto} />}
        />
        <Route
          path="/publications/:name"
          element={<PublicationInfo setScroll={setScrollInto} />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
