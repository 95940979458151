import { Card } from "react-bootstrap";
import "./NewsCardB.scss";
import moment from "moment";

interface IProps {
  image: string;
  date: string;
  news: string;
}

export default function NewsCardB(props: IProps) {
  return (
    <Card className="bg-dark-bg card-b">
      <Card.Img
        variant="top"
        src={props.image}
        alt="news"
        style={{ objectFit: "cover", height: "200px" }}
      />
      <Card.Body className="col justify-content-between">
        <div className="px-2">
          <Card.Title>
            <span
              className="badge badge-pill bg-primary badge-primary  px-3 py-2"
              style={{ fontWeight: "100", fontSize: "0.6em" }}
            >
              {moment(props.date).format("YYYY-MM-DD")}
            </span>
          </Card.Title>
          <div className="col-md-12">
            <p className=" mt-4" style={{ textAlign: "justify" }}>
              {props.news}
            </p>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}
