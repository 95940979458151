import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import { Link } from "react-router-dom";
import "./PublicationCard.scss";
import moment from "moment";

interface CardData {
  id: string;
  title: string;
  image: string;
  category: string;
  conference: string;
  authors: string[];
  date: string;
}

const PublicationCard = (props: CardData) => {
  return (
    <Card className="bg-dark-bg  my-3 h-100 mx-2">
      <Card.Img variant="top" src={props.image} />
      <Card.Body className="col justify-content-between px-4 py-4">
        <Badge pill bg="primary p-2 px-3 my-2 " style={{ fontWeight: "400" }}>
          {props.category}
        </Badge>{" "}
        <Card.Title className="h5">{props.title}</Card.Title>
        <div className="row">
          <p className="body text-gray">{props.conference}</p>
        </div>
        <div className="d-flex my-3 flex-wrap">
          {props.authors.map((author, index) => {
            return (
              <Badge
                pill
                bg="bg px-2 text-bg m-1 text-white p-2 px-3"
                key={index}
                style={{ fontWeight: "400" }}
              >
                {author}
              </Badge>
            );
          })}
        </div>
      </Card.Body>
      <div className="card-footer pb-3 d-flex justify-content-between px-4 pb-4">
        <Link
          to={`/publications/${props.title.replaceAll(" ", "-")}?id=${
            props.id
          }`}
          className="text-underline-none "
        >
          <p
            className="text-primary_2 text-underline-none p-0 m-0 link-text"
            style={{ textDecoration: "none" }}
          >
            View Publication
          </p>
        </Link>
        <p
          className=" text-gray p-0 mb-0 pb-1 mx-1"
          style={{ fontSize: "1em" }}
        >
          {moment(props.date).format("MMMM DD, YYYY")}
        </p>
      </div>
    </Card>
  );
};

export default PublicationCard;
