import NavBar from "../../../shared/NavBar/NavBar";

import { Link, useSearchParams } from "react-router-dom";

import "./PublicationInfo.scss";
import Badge from "react-bootstrap/Badge";

import Footer from "../../../shared/Footer/Footer";
import { useRef, useEffect, useState } from "react";
import { IPublication } from "../../../dtos/publications";
import axios from "axios";
import { apiUrl } from "../../../util/API";
import moment from "moment";
import { BlocksRenderer } from "@strapi/blocks-react-renderer";

interface IProps {
  setScroll?: any;
}
const PublicationInfo = (props: IProps) => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const topScroll = useRef<HTMLDivElement>(null);
  const [publication, setPublication] = useState<IPublication>();

  useEffect(() => {
    topScroll.current?.scrollIntoView();
  }, []);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/publications/${id}`, {
        params: {
          populate: "*",
        },
      })
      .then((res) => {
        setPublication(res.data.data);
      })
      .catch(() => {
        setPublication(undefined);
      });
  }, [id]);

  return (
    <div
      className="container-fluid bg-bg"
      style={{ padding: "0", margin: "0" }}
      ref={topScroll}
    >
      <div style={{ minHeight: "100vh" }}>
        <NavBar
          fullNav={false}
          active="publications"
          setScroll={props.setScroll}
        />
        <div className="container my-5 rounded fade-in">
          <div className="row  bg-dark-bg rounded mx-1 mx-md-0">
            <div className="col-md-12 ">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb p-0 m-0 my-2 mx-1">
                  <li className="breadcrumb-item">
                    <Link to="/publications">Publications</Link>
                  </li>
                  <li className="breadcrumb-item active text-gray">
                    {publication?.attributes.title}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
        <div className="container mt-2 rounded fade-in">
          <div className="row">
            <div className="col-md-12">
              <img
                src={apiUrl + publication?.attributes.image.data.attributes.url}
                alt="image1"
                className="img-fluid cover-image rounded"
              />
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-md-12">
              <p className="h4 text-white">{publication?.attributes.title}</p>
              {publication?.attributes.date && (
                <p className=" text-gray m-0">
                  {moment(publication.attributes.date).format(
                    "dddd, MMMM DD, YYYY"
                  )}
                </p>
              )}
            </div>
          </div>
          <hr style={{ borderTopColor: "gray" }}></hr>
        </div>

        <div className="container my-3 fade-in">
          <div className="row ">
            <div className="col-md-6">
              <div className="row">
                <p className="text-white h5 mx-2 mt-4 mt-md-0">Authors</p>
                <div className="col d-flex flex-wrap">
                  {publication?.attributes.authors.map((author, index) => {
                    return (
                      <Badge
                        pill
                        bg="dark-bg"
                        className="px-2 text-bg my-2 text-white p-2 px-3 mx-2"
                        style={{ fontSize: "0.9em", fontWeight: "400" }}
                        key={index}
                      >
                        {author}
                      </Badge>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col-md-3 ">
              <div className="row">
                <p className="text-white h5 mx-2 mt-4 mt-md-0">Conference</p>
                <div className="col d-flex flex-wrap">
                  <Badge
                    pill
                    bg="secondary"
                    className="px-2 text-bg my-2 text-white p-2 px-3 mx-2"
                    style={{ fontSize: "0.9em", fontWeight: "400" }}
                  >
                    {publication?.attributes.conference}
                  </Badge>
                </div>
              </div>
            </div>
            {publication?.attributes.paper.data && (
              <div className="col-md-3 ">
                <div className="row">
                  <p className="text-white h5  mt-4 mt-md-0">View Full Paper</p>
                  <div className="col d-flex flex-wrap">
                    <a
                      href={publication?.attributes.paper.data}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <button
                        className="btn btn-dark-bg mt-1 rounded-lg"
                        style={{ fontSize: "0.9em", fontWeight: "400" }}
                      >
                        Download PDF
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="container my-3 fade-in">
          <div className="row ">
            <p className="text-white h5 text-center mt-2">
              {publication?.attributes.mainParagraphTitle}
            </p>
            <div
              className="col-md-12 mt-2 text-white mx-2"
              style={{ textAlign: "justify" }}
            >
              {publication?.attributes.mainParagraph && (
                <BlocksRenderer
                  content={publication.attributes.mainParagraph}
                />
              )}
            </div>
          </div>
        </div>

        <div className="container my-3">
          <div className="row ">
            <p className="text-white h5 mx-2  ">Links</p>
            <div className="col-md-12">
              <ul className="text-white">
                <ul className="text-white ">
                  {publication?.attributes.links.map((link, index) => {
                    return (
                      <a
                        href={link.link}
                        rel="noreferrer"
                        target={"_blank"}
                        key={index}
                      >
                        <li className="text-gray">{link.text}</li>
                      </a>
                    );
                  })}
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PublicationInfo;
