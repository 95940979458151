import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import "./NavBar.scss";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/carel-logo.png";

interface NavBarProps {
  active?: string;
  anotherProp?: string;
  fullNav?: boolean;
  setScroll?: any;
}

const NavBar = (props: NavBarProps) => {
  const navigate = useNavigate();
  return (
    <Navbar bg="primary" expand="lg" variant="dark">
      <Container fluid>
        <Link to="/" style={{ textDecoration: "none" }}>
          <img src={logo} width="130px" className="my-2" alt="nav logo esmil" />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {props.fullNav ? (
            <>
              <Nav className="ms-auto">
                {props.active === "home"}
                <Link to="#home" className="text-underline-none">
                  <li
                    className=" text-underline-none mx-3 my-1 text-white "
                    style={{
                      textDecoration: "none",
                      fontWeight: props.active === "home" ? "700" : "400",
                    }}
                  >
                    Home
                  </li>
                </Link>

                <a
                  href="#about_section"
                  className="d-none"
                  style={{ textDecoration: "none" }}
                >
                  <li
                    className="mx-3 my-1  text-underline-none text-white "
                    style={{
                      textDecoration: "none",
                      fontWeight: props.active === "about" ? "700" : "400",
                    }}
                  >
                    About
                  </li>
                </a>

                {/* <a href="#news_section"  style={{textDecoration:"none"}}><li  className="mx-3 my-1 text-white"
                                    style={{textDecoration:"none",  fontWeight: props.active === "news" ? "700" : "400" }}>News</li></a> */}

                <a href="#projects_section" style={{ textDecoration: "none" }}>
                  <li
                    className="mx-3 my-1 text-white"
                    style={{
                      textDecoration: "none",
                      fontWeight: props.active === "projects" ? "700" : "400",
                    }}
                  >
                    Projects
                  </li>
                </a>
                <a
                  href="#publications_section"
                  style={{ textDecoration: "none" }}
                >
                  <li
                    className="mx-3 my-1 text-white"
                    style={{
                      textDecoration: "none",
                      fontWeight:
                        props.active === "publications" ? "700" : "400",
                    }}
                  >
                    Publications
                  </li>
                </a>
                {/*   <li href="#collaborators" className="mx-2 text-white" 
                                    style={{fontWeight:props.active==="collaborators" ? "700" : "400"}}>Collaborators</li> */}
                <a href="#contact_section" style={{ textDecoration: "none" }}>
                  <li
                    className="mx-3 my-1 text-white"
                    style={{
                      textDecoration: "none",
                      fontWeight: props.active === "contact" ? "700" : "400",
                    }}
                  >
                    Contact
                  </li>
                </a>
              </Nav>
            </>
          ) : (
            <Nav className="ms-auto">
              {props.active === "home"}
              <Link to="/" className="text-underline-none">
                <li
                  className="mx-3 my-1 text-white"
                  style={{
                    textDecoration: "none",
                    fontWeight: props.active === "home" ? "700" : "400",
                  }}
                  onClick={() => {
                    props.setScroll("hero");
                    navigate("/");
                  }}
                >
                  Home
                </li>
              </Link>

              <li
                className="mx-3 my-1 text-white d-none"
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  fontWeight: props.active === "about" ? "700" : "400",
                }}
                onClick={() => {
                  props.setScroll("about");
                  navigate("/");
                }}
              >
                About
              </li>

              {/* <Link to="/news" className="text-underline-none">
                                <li  className="mx-3 my-1 text-white"
                                    style={{ textDecoration:"none", fontWeight: props.active === "news" ? "700" : "400" }} >News</li> </Link> */}

              <Link to="/projects" className="text-underline-none">
                <li
                  className="mx-3 my-1 text-white"
                  style={{
                    textDecoration: "none",
                    fontWeight: props.active === "projects" ? "700" : "400",
                  }}
                >
                  Projects
                </li>
              </Link>
              <Link to="/publications" className="text-underline-none">
                <li
                  className="mx-3 my-1 text-white"
                  style={{
                    textDecoration: "none",
                    fontWeight: props.active === "publications" ? "700" : "400",
                  }}
                >
                  Publications
                </li>
              </Link>

              <li
                className="mx-3 my-1 text-white"
                style={{
                  cursor: "pointer",
                  textDecoration: "none",
                  fontWeight: props.active === "contact" ? "700" : "400",
                }}
                onClick={() => {
                  props.setScroll("contact");
                  navigate("/");
                }}
              >
                Contact
              </li>
            </Nav>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
