import React, { useState, useRef, useEffect } from "react";
import NavBar from "../../../shared/NavBar/NavBar";
import "../../../shared/styles/styles.scss";
import main_banner from "../../../assets/images/main_banner.webp";
import bg_image from "../../../assets/images/bg-image.webp";
import "./Home.scss";
import mdi_shield_check from "../../../assets/images/mdi_shield_check.webp";
import computer_vision from "../../../assets/images/vision.webp";
import bio from "../../../assets/images/bio.webp";
import nlp from "../../../assets/images/nlp.webp";
import energy from "../../../assets/images/energy.webp";
// import NewsCardA from "../../../shared/NewsCardA/NewsCardA";
import ProjectCard from "../../../shared/ProjectCard/ProjectCard";
import PublicationCard from "../../../shared/PublicationCard/PublicationCard";
import ToolsCard from "../../../shared/ToolsCard/ToolsCard";
import Profile from "../../../shared/Profile/Profile";
import synopsys_logo from "../../../assets/images/logos/synopsys.webp";
import lseg_logo from "../../../assets/images/logos/lseg.webp";
import fics_logo from "../../../assets/images/logos/fics.webp";
import uof_logo from "../../../assets/images/logos/uof.webp";
import Form from "react-bootstrap/Form";

import Footer from "../../../shared/Footer/Footer";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IProject } from "../../../dtos/project";
import axios from "axios";
import { apiUrl } from "../../../util/API";
import { IPublication } from "../../../dtos/publications";
// import { INews } from "../../../dtos/news";

interface HomeProps {
  scrollTo?: String;
}

const Home = (props: HomeProps) => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [publications, setPublications] = useState<IPublication[]>([]);
  // const [news, setNews] = useState<INews[]>([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const handleSpinnerClose = () => setShowSpinner(false);
  const handleSpinnerShow = () => setShowSpinner(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const handleMessageModalClose = () => setShowMessageModal(false);
  const handleMessageModalShow = () => setShowMessageModal(true);

  const aboutRef: any = useRef(null);
  const newsRef: any = useRef(null);
  const projectsRef: any = useRef(null);
  const publicationRef: any = useRef(null);
  const contactRef: any = useRef(null);
  const heroRef: any = useRef(null);

  useEffect(() => {
    axios
      .get(`${apiUrl}/api/projects`, {
        params: {
          populate: "*",
          sort: "showOrder:asc",
        },
      })
      .then((res) => {
        setProjects(res.data.data);
      })
      .catch(() => {
        setProjects([]);
      });

    axios
      .get(`${apiUrl}/api/publications`, {
        params: {
          populate: "*",
          sort: "date:desc",
        },
      })
      .then((res) => {
        setPublications(res.data.data);
      })
      .catch(() => {
        setPublications([]);
      });

    // axios
    //   .get(`${apiUrl}/api/news-stories`, {
    //     params: {
    //       populate: "*",
    //       sort: "createdAt:desc",
    //     },
    //   })
    //   .then((res) => {
    //     setNews(res.data.data);
    //   })
    //   .catch(() => {
    //     setNews([]);
    //   });
  }, []);

  const executeScroll = () => {
    if (props.scrollTo === "about") aboutRef.current.scrollIntoView();
    else if (props.scrollTo === "news") newsRef.current.scrollIntoView();
    else if (props.scrollTo === "projects")
      projectsRef.current.scrollIntoView();
    else if (props.scrollTo === "publications")
      publicationRef.current.scrollIntoView();
    else if (props.scrollTo === "contact") contactRef.current.scrollIntoView();
    else if (props.scrollTo === "hero") heroRef.current.scrollIntoView();
  };

  useEffect(() => {
    executeScroll();
  });

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [lookingFor, setLookingFor] = useState("");
  const [isInvalidName, setIsInvalidName] = useState(false);
  const [isInvalidEmail, setIsInvalidEmail] = useState(false);
  const [isInvalidMessage, setIsInvalidMessage] = useState(false);
  const [isInvalidLookingFor, setIsInvalidLookingFor] = useState(false);
  const [invalidName, setInvalidName] = useState("");
  const [invalidEmail, setInvalidEmail] = useState("");
  const [invalidMessage, setInvalidMessage] = useState("");
  const [invalidLookingFor, setInvalidLookingFor] = useState("");

  const validate = () => {
    let isValid = true;
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!name || name.length < 3) {
      setIsInvalidName(true);
      if (name) {
        setInvalidName("Your name should contain at least 3 characters");
      } else {
        setInvalidName("Your name is required");
      }
      isValid = false;
    } else {
      setIsInvalidName(false);
      setInvalidName("");
    }

    if (!email || !regex.test(email)) {
      setIsInvalidEmail(true);
      if (email) {
        setInvalidEmail("Your email is invalid");
      } else {
        setInvalidEmail("Your email is required");
      }
      isValid = false;
    } else {
      setIsInvalidEmail(false);
      setInvalidEmail("");
    }

    if (!message || message.length < 20) {
      setIsInvalidMessage(true);
      if (message) {
        setInvalidMessage("Your message should at least contain 20 characters");
      } else {
        setInvalidMessage("Your message is required");
      }
      isValid = false;
    } else {
      setIsInvalidMessage(false);
      setInvalidMessage("");
    }

    if (!lookingFor) {
      setIsInvalidLookingFor(true);
      setInvalidLookingFor("This field is required");
      isValid = false;
    } else {
      setIsInvalidLookingFor(false);
      setInvalidLookingFor("");
    }

    return isValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const contactUsSendButton: any = document.getElementById(
      "contact-us-send-button"
    );

    if (validate()) {
      handleSpinnerShow();
      const url =
        "https://us-central1-esmil-public-web.cloudfunctions.net/api/contactus";
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("Success:", data);
          setInvalidEmail("");
          setInvalidName("");
          setInvalidMessage("");
          setInvalidLookingFor("");
          setEmail("");
          setName("");
          setMessage("");
          setLookingFor("");
          contactUsSendButton.disabled = true;
          handleSpinnerClose();
          handleMessageModalShow();
          let countdownNum = 60;
          incTimer();
          function incTimer() {
            setTimeout(function () {
              if (countdownNum !== 0) {
                countdownNum--;
                contactUsSendButton.textContent =
                  "Send Message: " + countdownNum;
                incTimer();
              } else {
                contactUsSendButton.textContent = "Send Message";
                contactUsSendButton.disabled = false;
              }
            }, 1000);
          }
        })
        .catch((error) => {
          handleSpinnerClose();
          console.error("Error:", error);
        });
    }
  };

  return (
    <>
      <div
        className="container-fluid bg-bg"
        style={{ padding: "0", margin: "0" }}
      >
        <span ref={heroRef}></span>
        <NavBar active="home" fullNav={true} />
        <section>
          <div
            className="container-fluid text-white align home-hero "
            style={{
              backgroundImage: `url(${bg_image})`,
              backgroundSize: "cover",
            }}
          >
            <div className=" py-5 " id="home">
              <div className="col-md-12 text-center">
                <div>
                  <h1
                    data-aos="fade-up"
                    className="h1 text-center bolded-font mb-4"
                    style={{ fontWeight: "500" }}
                  >
                    Center for Applied Research <br />
                    and Enhanced Learning{" "}
                  </h1>
                  {/*  <a href="#about_section">
                    <img data-aos="fade-up" 
                      src={down_icon}
                      width={"60px"}
                      className="down-btn "
                      alt="go-down"
                    />
                  </a> */}
                </div>
                <img
                  data-aos="fade-up"
                  data-aos-duration="600"
                  src={main_banner}
                  className="py-5 main-image "
                  alt="ESMIL"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="py-5 d-none" ref={aboutRef} id="about_section">
          <div className="container">
            <div className="row text-white">
              <div className="col-md-12">
                <p className="h2 text-center mb-4" data-aos="fade-up">
                  About
                </p>
                <p
                  className=""
                  style={{ textAlign: "justify", fontSize: "1.1em" }}
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="300"
                >
                  Founded by staff members at the University of Moratuwa - the
                  premier technical university in Sri Lanka, CAREL brings
                  together researchers from a variety of backgrounds from all
                  over the world. We work on cutting-edge applied research areas
                  and focus on students' skill development. We have built an
                  ecosystem where the best come together and contribute to
                  impactful projects.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="py-5">
          <div className="container">
            <p className="h2 text-center text-white mb-4" data-aos="fade-up">
              Current Research Areas
            </p>
            <div className="row text-white pt-1 justify-content-between">
              <div className="col-md-2 my-2 col-12 research-area-card ">
                <div
                  className="card bg-dark-bg rounded h-100"
                  data-aos="fade-right"
                  data-aos-delay="300"
                >
                  <div className="row align-items-center justify-content-start p-4">
                    <img
                      src={computer_vision}
                      style={{ width: "70px" }}
                      className="mx-1"
                      alt="mdi_shield_check"
                    />
                    <p className="h5 mt-3">Computer Vision</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 my-2 col-12 research-area-card">
                <div
                  className="card bg-dark-bg rounded h-100"
                  data-aos="fade-right"
                  data-aos-delay="400"
                >
                  <div className="row  align-items-center justify-content-start p-4">
                    <img
                      src={mdi_shield_check}
                      style={{ width: "70px" }}
                      className="mx-1"
                      alt="mdi_shield_check"
                    />
                    <p className="h5 mt-3">Security and Trust</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 my-2 col-12 research-area-card ">
                <div
                  className="card bg-dark-bg rounded h-100"
                  data-aos="fade-right"
                  data-aos-delay="500"
                >
                  <div className="row align-items-center justify-content-start p-4">
                    <img
                      src={nlp}
                      style={{ width: "70px" }}
                      className="mx-1"
                      alt="mdi_shield_check"
                    />
                    <p className="h5 mt-3">Natural Language Processing</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 my-2 col-12 research-area-card ">
                <div
                  className="card bg-dark-bg rounded h-100"
                  data-aos="fade-right"
                  data-aos-delay="600"
                >
                  <div className="row align-items-center justify-content-start p-4">
                    <img
                      src={energy}
                      style={{ width: "70px" }}
                      className="mx-1"
                      alt="mdi_shield_check"
                    />
                    <p className="h5 mt-3">Digitalization of Energy Systems</p>
                  </div>
                </div>
              </div>
              <div className="col-md-2 my-2 col-12 research-area-card">
                <div
                  className="card bg-dark-bg rounded h-100"
                  data-aos="fade-right"
                  data-aos-delay="600"
                >
                  <div className="row align-items-center justify-content-start p-4">
                    <img
                      src={bio}
                      style={{ width: "70px" }}
                      className="mx-1"
                      alt="mdi_shield_check"
                    />
                    <p className="h5 mt-3">Bioinformatics</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="py-5" ref={newsRef} id="news_section">
          <div className="container">
            <div
              className="d-flex align-items-center justify-content-between"
              data-aos="fade-up"
            >
              <p className="h2 text-white ">Latest News</p>
              <Link to="/news">
                <button className="btn btn-primary">View All News</button>
              </Link>
            </div>
            <div className="row text-white pt-1">
              <div className="col-md-12">
                {news.slice(0, 3).map((news, index) => {
                  return (
                    <NewsCardA
                      news={news.attributes.news}
                      date={news.attributes.date}
                      tag={news.attributes.tag}
                      key={index}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </section> */}
        <section className="py-5" ref={projectsRef} id="projects_section">
          <div className="container">
            <div
              className="d-flex align-items-center justify-content-between "
              data-aos="fade-up"
            >
              <p className="h2 text-white ">Collaborative Projects</p>
              <Link to="/projects">
                <button className="btn btn-primary">View All Projects</button>
              </Link>
            </div>
            <div className="row text-white pt-1">
              {projects.slice(0, 3).map((project, index) => {
                return (
                  <div
                    className="col-md-4 py-2"
                    data-aos="fade-right"
                    data-aos-delay={index * 100}
                    key={index}
                  >
                    <ProjectCard
                      title={project.attributes.title}
                      category={project.attributes.category}
                      status={project.attributes.status}
                      image={
                        apiUrl + project.attributes.image.data.attributes.url
                      }
                      peopleInvolved={project.attributes.peopleInvolved}
                      id={project.id}
                      learnMoreAlternative={
                        project.attributes.learnMoreAlternative
                      }
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section
          className="py-5"
          ref={publicationRef}
          id="publications_section"
        >
          <div className="container">
            <div
              className="d-flex align-items-center justify-content-between"
              data-aos="fade-up"
            >
              <p className="h2 text-white">Publications</p>
              <Link to="/publications">
                <button className="btn btn-primary">
                  View All Publications
                </button>
              </Link>
            </div>
            <div className="row text-white pt-1">
              {publications.slice(0, 3).map((publication, index) => {
                return (
                  <div
                    className="col-md-4 py-3"
                    key={index}
                    data-aos="fade-right"
                    data-aos-delay={index * 100}
                  >
                    <PublicationCard
                      id={publication.id}
                      title={publication.attributes.title}
                      image={
                        apiUrl +
                        publication.attributes.image.data.attributes.url
                      }
                      category={publication.attributes.category}
                      conference={publication.attributes.conference}
                      authors={publication.attributes.authors}
                      date={publication.attributes.date}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <section className="py-5" style={{ display: "none" }}>
          <div className="container">
            <p className="h1 text-center text-white mb-4">Tools Developed</p>
            <div className="row  pt-1">
              <div className="col-md-4 my-2">
                <ToolsCard />
              </div>

              <div className="col-md-4 my-2">
                <ToolsCard />
              </div>

              <div className="col-md-4 my-2">
                <ToolsCard />
              </div>
            </div>
          </div>
        </section>
        <section className="py-5" style={{ display: "none" }}>
          <div className="container">
            <p className="h1 text-center text-white mb-4" id="collaborators">
              Key Personnel
            </p>
            <div className="row  pt-1 d-flex">
              <div className="col-md-4 my-2">
                <Profile />
              </div>

              <div className="col-md-4 my-2">
                <Profile />
              </div>

              <div className="col-md-4 my-2">
                <Profile />
              </div>
            </div>
          </div>
        </section>

        <section className="py-5" style={{ marginBottom: "5rem" }}>
          <div className="container">
            <p
              className="h2 text-center text-white mb-4"
              id="collaborators"
              data-aos="fade-up"
            >
              Friends & Allies
            </p>
            <div className=" row pt-1 align-items-center justify-content-evenly">
              <div
                className="col-md-3  align-items-center justify-content-center d-flex"
                data-aos="fade-right"
                data-aos-delay="100"
              >
                <img src={synopsys_logo} alt="logo" className="img-fluid m-3" />
              </div>
              <div
                className="col-md-3 align-items-center justify-content-center d-flex"
                data-aos="fade-right"
                data-aos-delay="300"
              >
                <img src={lseg_logo} alt="logo" className="img-fluid m-3" />
              </div>
              <div
                className="col-md-3 align-items-center justify-content-center d-flex"
                data-aos="fade-right"
                data-aos-delay="400"
              >
                <img src={fics_logo} alt="logo" className="img-fluid m-3" />
              </div>
              <div
                className="col-md-3 align-items-center justify-content-center d-flex"
                data-aos="fade-right"
                data-aos-delay="500"
              >
                <img src={uof_logo} alt="logo" className="img-fluid m-3" />
              </div>
            </div>
          </div>
        </section>

        <section className="py-5 bg-primary" ref={contactRef}>
          <div className="container py-1" data-aos="fade-up">
            <p
              className="h2 text-center text-white mb-2 mt-0"
              id="contact_section"
            >
              Contact
            </p>
            <p
              className="text-white  text-center my-4"
              style={{ fontWeight: "100", fontSize: "1.1em" }}
            >
              We are always open to challenging problems and exceptional talent.
              If you have a problem we can work on, or you think you are a good
              fit for our team, hit us up!
            </p>
            <div className="container contact-form align-items-center">
              <div className="my-2 py-3">
                <div className="text-white">
                  <Form
                    className="needs-validation"
                    validated={false}
                    onSubmit={handleSubmit}
                  >
                    <div className="row align-items-start">
                      <div className="col-md-6">
                        <Form.Group className="mb-3" controlId="formBasicName">
                          <Form.Control
                            type="text"
                            placeholder="Your Name"
                            name="name"
                            value={name}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setName(e.target.value);
                            }}
                          />
                          {isInvalidName ? (
                            <p className="text-danger">{invalidName}</p>
                          ) : (
                            <></>
                          )}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            placeholder="Your Email"
                            name="email"
                            value={email}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setEmail(e.target.value);
                            }}
                          />
                          {isInvalidEmail ? (
                            <p className="text-danger">{invalidEmail}</p>
                          ) : (
                            <></>
                          )}
                        </Form.Group>
                        <Form.Group
                          className="mb-3 d-flex flex-wrap"
                          controlId="formBasicCheckbox"
                        >
                          <Form.Check
                            type="radio"
                            name="lookingFor"
                            label="Looking to collaborate"
                            className="mx-2"
                            id="1"
                            value={`Looking to collaborate`}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setLookingFor(e.target.value);
                            }}
                            checked={lookingFor === `Looking to collaborate`}
                          />
                          <Form.Check
                            type="radio"
                            name="lookingFor"
                            label="Looking to join the team"
                            className="mx-2"
                            id="2"
                            value={`Looking to join the team`}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setLookingFor(e.target.value);
                            }}
                            checked={lookingFor === `Looking to join the team`}
                          />
                          {isInvalidLookingFor ? (
                            <p className="text-danger">{invalidLookingFor}</p>
                          ) : (
                            <></>
                          )}
                        </Form.Group>
                      </div>
                      <div className="col-md-6">
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlTextarea1"
                        >
                          <Form.Control
                            as="textarea"
                            rows={5}
                            placeholder="Your Message"
                            name="message"
                            value={message}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setMessage(e.target.value);
                            }}
                          />
                          {isInvalidMessage ? (
                            <p className="text-danger">{invalidMessage}</p>
                          ) : (
                            <></>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                    <div className=" d-flex align-items-center justify-content-center">
                      <Button
                        className="send-btn font-family-class fs-6"
                        variant="none"
                        type="submit"
                        style={{ fontWeight: 500 }}
                        id="contact-us-send-button"
                      >
                        Send Message
                      </Button>
                    </div>
                  </Form>

                  {/*  {!showBacktoTop && (
                   
                      <button className="btn btn-primary back-to-top-button "  onClick={scrollToTop}>
                        <img src={back_top_icon} alt="back to top"/>
                      </button>
                  
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* spinner */}
        <div id="spinner_modal">
          <Modal
            show={showSpinner}
            onHide={handleSpinnerClose}
            animation={true}
            centered
            backdrop="static"
          >
            <Modal.Body className="text-center p-5">
              <Spinner animation="border" variant="primary" />
              <p className="lead mt-2">Loading... Please Wait</p>
            </Modal.Body>
          </Modal>
        </div>

        {/* message modal */}
        <div id="message_modal">
          <Modal
            show={showMessageModal}
            onHide={handleMessageModalClose}
            animation={true}
            centered
          >
            <Modal.Body className="text-center p-3">
              <p className="lead mt-2">Your message as been sent.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                className="text-white"
                onClick={handleMessageModalClose}
              >
                Okay
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default Home;
