import Card from 'react-bootstrap/Card';
import Badge from 'react-bootstrap/Badge';

interface CardData {
    name?:String,
    description?:String,
    language?:String,
    label?:String,
}

const ToolsCard = (props:CardData) => {
    return (
        <Card className="bg-dark-bg  my-3 h-100 mx-2" >
            <Card.Body className='col justify-content-between '>
                <Card.Title className='h5 text-white'>gernet {props.description}</Card.Title>
                <p className='text-gray'>Corem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc vulputate libero et velit interdum, ac aliquet odio mattis.
                    Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
                </p>
                <div className='d-flex align-items-center text-white'>
                    <p className='mt-2'>Language</p>
                    <Badge pill bg="bg p-2 px-3 mx-2 ">
                        C++
                    </Badge>{' '}
                </div>
               
            </Card.Body>
            <div className='card-footer'>
            <p className='text-primary'>ISPASS 2009, DAC 2020</p>
            </div>
        </Card>)
}

export default ToolsCard;