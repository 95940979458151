import React from "react";
import "./NewsCardC.scss";

interface CardData {
  date: string;
  news: string;
  tag?: String;
}

export default function NewsCardC(props: CardData) {
  return (
    <>
      <div className="card bg-dark-bg py-3 my-3 f-news-card">
        <div className="container row">
          <div className="col-md-3 align-items-center justify-content-start px-3">
            <span
              className="badge badge-pill bg-primary badge-primary lead px-3 py-2"
              style={{ fontWeight: "100" }}
            >
              {props.date}
            </span>
          </div>
          <div className="col-md-12 px-3">
            <p className=" mt-3" style={{ textAlign: "justify" }}>
              {props.news}
              <span
                className="badge badge-pill bg-warning text-bg badge-primary lead mx-2"
                style={{
                  display: props.tag === "" || props.tag === null ? "none" : "",
                }}
              >
                {props.tag}
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
