import profile_pic from "../../assets/images/profile.webp";
import "./Profile.scss";
interface ProfileData {
    name?:String,
    image?:any,
    desgination?:String
}
const Profile =(props:ProfileData)=>{
    return (
        <div className="profile container align-items-center justify-content-center my-3">
            <img src={profile_pic} alt="userprofle" />
            <p className="h4 text-white text-center mt-3">John Doe {props.name}</p>
            <p className="text-center text-white">Lorem ipsum dolor </p>
        </div>
    )
}


export default Profile;